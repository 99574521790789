// ЗАКРЫТЬ МОДАЛКУ С ФОРМОЙ И ОТКРЫТЬ МОДАЛКУ "СПАСИБО" ПРИ ОТПРАВКЕ ФОРМЫ
const forms = document.querySelectorAll("form");
const modalSuccess = new bootstrap.Modal(document.getElementById("modalSuccess"), {});

forms.forEach((form) => {
  var currentModal = new bootstrap.Modal(form.closest(".modal"), {});
  form.addEventListener("submit", function (e) {
    if (form.checkValidity()) {
      modalSuccess.show();
      currentModal.hide();
      e.preventDefault();
    }
  });
});

// ОТОБРАЗИТЬ ТОСТЫ
const toastElList = document.querySelectorAll(".toast");
const toastList = [...toastElList].map((toastEl) => new bootstrap.Toast(toastEl));
toastList.forEach((toast) => {
  toast.show();
});
